import _ from 'lodash';

export {
  CeType,
  appToAppDefId,
  AppType,
  AppToAppDefId,
  COLORATION_METHOD_TYPE,
  ESocialPlatform,
} from '@wix/adi-core-types';

export type {
  ContentManager,
  PresetPreview,
  themeChooser,
  newThemes,
  newThemeExpander,
  santaFontMetadata,
  themeChooserUtils,
  PresetApi,
  ICmOptions,
  MediaItem,
  ContentItem,
  SitePart,
  ElementSubType,
  ContentItems,
  HeaderFooterContentItem,
  PaaSRenderedModel,
  IModuleDependencies,
  ThemeKit,
  ISuggestResult,
  ListContentItem,
  OfferingTypeCMS,
  IPageDescription,
  ISectionBrief,
  PreferredMedia,
  PaaSRenderedModelNode,
  PaaSPageRenderedModel,
  PageAlternative,
  PreviewFlag,
  ImageMediaItem,
} from '@wix/adi-preset-api';

export { PresetPreviewComponent } from './presetPreviewComponent';

export const adapter = _.memoize(async () => {
  const [{ SantaModuleDependencies }, presetAPI] = await Promise.all([
    import('@/editorPaas'),
    import('./adapter'),
  ]);
  presetAPI.initialize(new SantaModuleDependencies());
  return presetAPI;
});
