import React, { useEffect, useState } from 'react';
import type { PresetPreview } from '#packages/presetApi';
import { adapter } from '#packages/presetApi';

export const PresetPreviewComponent = (() => {
  let PresetPreviewComp: typeof PresetPreview | undefined;

  return function PresetPreviewWrapper(
    props: React.ComponentProps<typeof PresetPreview>,
  ) {
    const [ready, setReady] = useState(!!PresetPreviewComp);
    useEffect(() => {
      if (!ready) {
        adapter().then(({ PresetPreview }) => {
          PresetPreviewComp = PresetPreview;
          setReady(true);
        });
      }
    }, [ready]);

    return <>{ready && <PresetPreviewComp {...props} />}</>;
  };
})();
